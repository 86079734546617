import React from 'react'
import {
    TextSectionElem
} from './TextSectionStyles'
import { motion } from 'framer-motion'
import { Container } from '../../styles'

const transition = {
    duration: 0.8,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const TextSection = ({bgColor, textColor, children}) => {
    return (
        <TextSectionElem
            style={{backgroundColor: `${bgColor}`, color: `${textColor}`}}
        >
            <Container>
                <motion.div
                    initial={{
                        opacity: 0,
                        y: 80
                    }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            ...transition
                        }
                    }}
                >
                    {children}
                </motion.div>
            </Container>
        </TextSectionElem>
    )
}

export default TextSection
