import React from 'react'
import { Eyebrow, Container } from "../../styles"
import {
    BannerElem,
    BannerCopy,
    BannerTitle,
    BannerImage
} from './BannerStyles'
import Image from 'gatsby-image'

const transition = {
    duration: 0.8,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const Banner = ({imageSrc, eyeBrowCopy, headingCopy}) => {
    return (
        <BannerElem>
            <Container flex alignCenter fullHeight>
                <BannerCopy
                    initial={{
                        opacity: 0,
                        x: "-80"
                    }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                            ...transition
                        }
                    }}
                >
                    <Eyebrow green>
                        {eyeBrowCopy}
                    </Eyebrow>
                    <BannerTitle>{headingCopy}</BannerTitle>
                </BannerCopy>
                <BannerImage
                    initial={{
                        opacity: 0,
                        x: 80
                    }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                            ...transition
                        }
                    }}
                >
                    <Image
                        fluid={imageSrc} 
                        alt=""
                    />
                </BannerImage>
            </Container>
        </BannerElem>
    )
}

export default Banner
