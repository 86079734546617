import styled from 'styled-components'

export const TextSectionElem = styled.section`
    padding: 40px 0;

    @media(min-width: 900px) {
        padding: 80px 0;
    }

    p, li {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.618;
        @media(min-width: 900px) {
            max-width: 900px;
            font-size: 22px;
        }
    }

    ul {
        padding-left: 25px;
        list-style: disc;
        li {    
            margin-bottom: 16px;
        }
    }
`;