import styled from 'styled-components'
import { defaultTheme, Container } from '../../styles'
import {motion} from 'framer-motion'

export const BannerElem = styled.section`
    padding: 0 12px;
    background: ${defaultTheme.darkBlue};

    ${Container} {
        position: relative;
        flex-direction: column;
        padding: 140px 0 0;
        @media(min-width: 900px) {
            flex-direction: row;
            padding: 120px 0 0;
        }
    }
`;

export const BannerCopy = styled(motion.div)`

`;

export const BannerTitle = styled.h1`
    margin: 12px 0 0;
    max-width: 90%;
    color: ${defaultTheme.white};
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.031em;
    line-height: 1.25;

    @media(min-width: 360px) {
        font-size: 42px;
    }

    @media(min-width: 750px) {
        font-size: 72px;
    }

    @media(min-width: 900px) {
        margin-bottom: 72px;
        max-width: 600px;
    }
`;

export const BannerImage = styled(motion.div)`
    margin-top: 32px;
    width: 100%;
    @media(min-width: 900px) {
        margin-top: 0;
        width: 50%;
    }
`;